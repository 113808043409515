import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile from "../components/Tile";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import img_Hero from '../images/WerkzeugeFinden/hero_bg.svg';
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_05 from "./../images/WerkzeugeFinden/Img_05.jpg";
import img_06 from "./../images/WerkzeugeFinden/Img_06.jpg";
import img_07 from "./../images/WerkzeugeFinden/Img_07.jpg";
import img_08 from "./../images/WerkzeugeFinden/Img_08.jpg";
import img_09 from "./../images/WerkzeugeFinden/Img_09.jpg";
import img_10 from "./../images/WerkzeugeFinden/Img_10.jpg";
import links from "./links";


const TilesData: TileData[] = [
    {
        title: "Workday",
        link: "https://wd3.myworkday.com/steeleurope/d/home.htmld",
        external: true,
        description: `
            Auf dem Gipfel der Produktivität. Erleben Sie
            eine innovative Plattform, die Ihnen
            intelligente Insights und Echtzeitdaten bietet.
            Nutzen Sie umfassende Analytik und intuitive
            Tools, um Ihre Teams erfolgreich zu führen.
        `,
    },
    {
        title: "Service Point",
        link: "https://p11.az.steeleu.com/irj/portal",
        external: true,
        description: `
            Ihr Basislager für erstklassigen Support und
            Lösungen: Funktionsbeschreibungen,
            Abwesenheiten genehmigen, Reisekosten
            genehmigen, Leistungsbeurteilung AT
            durchführen und vieles mehr finden Sie hier.
        `,
    },
    {
        title: "AUDITORplus",
        link: "https://steeleurope.sharepoint.com/sites/as-auditor-plus/SitePages/Auditor+.aspx",
        external: true,
        description: `
            Bewältigen, erstellen und pflegen Sie z.B. Gefährdungsbeurteilungen, Maßnahmenverwaltung, planen Unterweisungen und führen diese durch.
        `,
    },
    {
        title: "Mobiles Arbeiten",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/beruf-privat/hybrides-arbeiten-bei-steel-nach-corona",
        external: true,
        description: `
            Freiheit und Flexibilität auf neuen Wegen.
            Erkunden Sie Tools und Strategien für
            virtuelle Zusammenarbeit und effizientes
            Arbeiten in der modernen Arbeitswelt.
        `,
    },
    {
        title: "Betriebliche Qualifizierungsplanung",
        link: "https://p11.az.steeleu.com/irj/portal",
        external: true,
        description: `
            Tauchen Sie ein in die Welt der betrieblichen Qualifizierungsplanung und lernen Sie, wie Sie Ihre Ressourcen optimal einsetzen, um Ihre Ziele effizienter zu erreichen. Alle Qualifikationen, die sich aus den betriebsspezifischen Anforderungen und verantworteten Prozessen ergeben, können Sie hier pflegen.
        `,
        keywords: ["BQP"]
    },
    {
        title: "Quentic",
        link: "https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/direx/quentic",
        external: true,
        description: `
            Erfahren Sie hier, wie Sie rechtliche
            Stolpersteine vermeiden und sich auf den
            neuesten Stand der arbeitsrechtlichen
            Bestimmungen bringen.
        `,
    },
    {
        title: "Document Desk",
        link: "https://sp.steeleurope.com/sites/Governance/SitePages/Tiles.aspx",
        external: true,
        description: `
            Organisieren, teilen und verwalten Sie
            Dokumente effizient von einem Ort aus.
            Vereinfachen Sie Ihre Arbeitsprozesse und
            sparen Sie Zeit.
        `,
    },
    {
        title: "Vergabe betrieblicher Rollen",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/vergabe-betrieblicher-rollen",
        external: true,
        description: `
            Die Dokumentation „Betrieblicher Rollen“ ist bedeutsam für in - und externe Audits, da in aller Regel auch eine Dokumentationspflicht besteht. Es gibt Beauftragungen und Rollen, die für Ihren Bereich und unser Unternehmen wichtig sind. Entdecken Sie hier die für Ihren Fachbereich benötigten betrieblichen Rollen.
        `,
    },
    {
        title: "Legal & Compliance",
        link: "https://steel-net.thyssenkrupp-steel.com/de/organisation/legal-compliance-co-lc-se",
        external: true,
        description: `
        In diesem Bereich dreht sich alles um die Einhaltung von Gesetzen, Vorschriften und betrieblichen Standards, um sicherzustellen, dass Führungskräfte verantwortungsbewusst und im Einklang mit den rechtlichen Rahmenbedingungen agieren.
        `,
    },
    {
        title: "LuP@Steel",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/grundlagen/lupsteel",
        external: true,
        description: `
        Lernen Sie unseren integrierten Talent- & Performance-Prozess zur Leistungsbeurteilung und Potenzialentwicklung kennen. Hier finden Sie alle Informationen zu der jährlichen Statusbetrachtung von Leistung, Potenzial und Entwicklung in Workday.
        `,
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "Werkzeuge finden",
        link: links["Werkzeuge finden"],
        description: "Unebenes Terrain und schwierige Herausforderungen erfordern passgenaues Equipment.",
        type: SearchItemType.InternalLink,
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData))
];

export default function WerkzeugeFindenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <Section>
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-green">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: Werkzeuge finden</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Unebenes Terrain und schwierige Herausforderungen erfordern passgenaues Equipment.
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Ein Set an effektiven Strategien und Techniken kann somit den Unterschied<br />
                    zwischen Erfolg und Misserfolg bedeuten.
                </HeroIchMöchte.Body>
                <HeroIchMöchte.BlueBody className="max-w-2xl">
                    Egal, ob es sich um eine schwierige Verhandlungssituation oder eine komplexe Projektplanung handelt – hier finden Sie wertvolle Tools, um für alle Situationen gewappnet zu sein und das Gipfelkreuz Ihres Erfolgs zu erreichen.
                </HeroIchMöchte.BlueBody>
            </HeroIchMöchte>
        </Section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <Tile image={img_05} />
                <Tile className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[0].link}
                    external={TilesData[0].external}
                    title={TilesData[0].title}
                >
                    {TilesData[0].description}
                </Tile>
                <Tile className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[1].link}
                    external={TilesData[1].external}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                {/*  */}
                <Tile className="bg-tk-blue-medium hover:bg-tk-blue-medium-hover"
                    linkTo={TilesData[2].link}
                    external={TilesData[2].external}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile image={img_06} />
                <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[3].link}
                    external={TilesData[3].external}
                    title={TilesData[3].title}
                >
                    {TilesData[3].description}
                </Tile>
                {/*  */}
                <Tile image={img_07} />
                <Tile className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[4].link}
                    external={TilesData[4].external}
                    title={TilesData[4].title}
                >
                    {TilesData[4].description}
                </Tile>
                <Tile className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[9].link}
                    external={TilesData[9].external}
                    title={TilesData[9].title}
                >
                    {TilesData[9].description}
                </Tile>
                {/*  */}
                <Tile className="bg-tk-orange hover:bg-tk-orange-hover"
                    linkTo={TilesData[5].link}
                    external={TilesData[5].external}
                    title={TilesData[5].title}
                >
                    {TilesData[5].description}
                </Tile>
                <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[6].link}
                    external={TilesData[6].external}
                    title={TilesData[6].title}
                >
                    {TilesData[6].description}
                </Tile>
                <Tile image={img_08} />
                {/*  */}
                <Tile double className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[7].link}
                    external={TilesData[7].external}
                    title={TilesData[7].title}
                >
                    {TilesData[7].description}
                </Tile>
                <Tile double image={img_09} />
                <Tile double image={img_10} />
                <Tile double className="bg-tk-blue-medium hover:bg-tk-blue-medium-hover"
                    linkTo={TilesData[8].link}
                    external={TilesData[8].external}
                    linkTo2={"https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=21ebda19-17c8-452b-803d-3d10a4876402"}
                    linkTo2Text="Unternehmerische Compliance-Verantwortung"
                    external2
                    title={TilesData[8].title}
                >
                    {TilesData[8].description}
                </Tile>
            </div>
        </Section>
    );
}

