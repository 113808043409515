import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSearchContext } from "../context/SearchContext";
import img_arrow from "../images/menu_arrow.svg";
import links from "../pages/links";
import IconExternalLink from "./IconExternalLink";
import NavLinkWithContextMenu from "./NavLinkWithContextMenu";

export default function Nav(props: {}): JSX.Element {
    const { t } = useTranslation();

    return (
        <nav className="flex flex-col items-start justify-between w-full text-sm font-medium select-none lg:items-center lg:flex-row text-tk-brand font-tk-regular">
            <span className="p-3 font-tk-medium lg:p-0">Ich möchte:</span>
            <MenuItem linkTo={links["Führungsverständnis stärken"]} text={t('nav.Führungsverständnis stärken')}>
                <SubMenuItem linkTo={links["Meine Rolle als Führungskraft"]} text="Meine Rolle als Führungskraft" />
                <SubMenuItem linkTo={links["Führungsinstrumente"]} text="Führungsinstrumente" />
                <SubMenuItem linkTo={links["Führungs und Leistungskultur"]} text="Führungs- & Leistungskultur" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal"} external text="Das Entwicklungsportal" />
                <SubMenuItem linkTo={links['LGBTQ+_Vielfalt als Chance']} text="Vielfalt als Chance – Vielfalt leben" />
            </MenuItem>
            <MenuItem linkTo={links["mich einarbeiten"]} text={t('nav.Einarbeiten')}>
                <SubMenuItem linkTo={"https://wd3.myworkday.com/steeleurope/learning"} external text="tk Leadership Essentials" />
                <SubMenuItem linkTo={links["Meine Rolle als Führungskraft"]} text="Meine Rolle als Führungskraft" />
                <SubMenuItem linkTo={links["Führungs und Leistungskultur"]} text="Führungs- und Leistungskultur " />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/fuehrungsportal/transition-programm-bei-tk-steel/online-guide-first-90-days"} external text="Veränderungen begleiten" />
                <SubMenuItem linkTo={links["Werkzeuge finden"]} text="Werkzeuge finden" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/digitales-coaching"} external text="Coach Hub" />
            </MenuItem>
            <MenuItem linkTo={links['meine Aufgaben kennen']} text={t('nav.Aufgaben kennen')}>
                <SubMenuItem linkTo={links["Mitarbeiter gewinnen"]} text="Mitarbeitende gewinnen" />
                <SubMenuItem linkTo={links['Mitarbeiter onboarden']} text="Mitarbeitende onboarden" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/organisation/safety-security-se-ss/arbeitssicherheit"} external text="Arbeitssicherheit" />
                <SubMenuItem linkTo={links["Gut kommunizieren"]} text="Gut kommunizieren" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/direx"} external text="Rechtssicher handeln" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/rund-ums-arbeiten/hybrides-arbeiten-bei-steel/hybrides-arbeiten-erfolgreich-gestalten"} external text="Hybride Arbeit gestalten" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/ausstieg"} external text="Mitarbeitende offboarden" />
                <SubMenuItem linkTo={"https://wd3.myworkday.com/steeleurope/learning"} external text="Lernangebote" />
                <SubMenuItem linkTo={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/qualifizierungsplanung"} external text="Qualifizierungsplanung durchführen" />
            </MenuItem>
            <MenuItem linkTo={links["Gespräche führen"]} text={t('nav.Gespräche führen')}>
                <SubMenuItem linkTo={links["Feedback nehmen und geben"]} text="Feedback" />
                <SubMenuItem linkTo={links["Gut kommunizieren"]} text="Gut kommunizieren" />
                <SubMenuItem linkTo={links["Führungsinstrumente"]} text="Führungsinstrumente" />
                <SubMenuItem linkTo={links["Mitarbeiter gewinnen"]} text="Bewerbungsgespräche führen" />
                <SubMenuItem linkTo="https://wd3.myworkday.com/steeleurope/learning" text="Lernangebote" external />
            </MenuItem>
            <MenuItem linkTo={links['entwickeln']} text={t('nav.Entwickeln')} last>
                <SubMenuItem linkTo={links["mich entwickeln"]} text="Mich entwickeln" />
                <SubMenuItem linkTo={links["Mitarbeitende entwickeln"]} text="Mitarbeitende entwickeln" />
                <SubMenuItem linkTo={links["Meinen Verantwortungsbereich"]} text="Meinen Verantwortungsbereich strategisch weiterentwickeln" />
                <SubMenuItem linkTo="https://wd3.myworkday.com/steeleurope/learning" text="Lernangebote" external />
            </MenuItem>
            <MenuItem linkTo={links["Werkzeuge finden"]} text={t('nav.Werkzeuge finden')} last>
                <SubMenuItem linkTo="https://wd3.myworkday.com/steeleurope/d/home.htmld" text="Workday" external />
                <SubMenuItem linkTo="https://p11.az.steeleu.com/irj/portal" text="Service Point" external />
                <SubMenuItem linkTo="https://steeleurope.sharepoint.com/sites/as-auditor-plus/SitePages/Auditor+.aspx" text="AUDITORplus" external />
                <SubMenuItem linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/beruf-privat/hybrides-arbeiten-bei-steel-nach-corona" text="Mobiles Arbeiten" external />
                <SubMenuItem linkTo="https://p11.az.steeleu.com/irj/portal" text="BQP" external />
                <SubMenuItem linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/grundlagen/lupsteel" text="LuP@Steel" external />
                <SubMenuItem linkTo="https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/direx/quentic" text="Quentic" external />
                <SubMenuItem linkTo="https://sp.steeleurope.com/sites/Governance/SitePages/Tiles.aspx" text="Document Desk" external />
                <SubMenuItem linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/vergabe-betrieblicher-rollen" text="Vergabe betrieblicher Rollen" external />
                <SubMenuItem linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/legal-compliance-co-lc-se" text="Legal & Compliance" external />
            </MenuItem>
        </nav>
    );
}

function SubMenuItem(props: { linkTo: string; text: string; external?: boolean }): JSX.Element {
    const navLinkClass = ({ isActive, isPending }: {
        isActive: boolean;
        isPending: boolean;
    }) => {
        return isActive ? `underline underline-offset-4 flex w-full` : 'flex w-full';
    };

    return (
        <div className="relative border-b py-1.5 last:border-b-white border-b-solid border-b-grey-200 whitespace-nowrap hover:border-b-tk-brand hover:underline-offset-4">
            {
                !props.external &&
                <NavLinkWithContextMenu
                    target='_self'
                    to={props.linkTo}
                    className={navLinkClass}
                >
                    <span className="flex-1">{props.text}</span>
                </NavLinkWithContextMenu>
            }
            {
                props.external &&
                <NavLink
                    target={'_blank'}
                    to={props.linkTo}
                    className={navLinkClass}
                >
                    <span className="flex-1">{props.text}</span>
                    <IconExternalLink className="inline w-3 ml-4 justify-self-end fill-tk-brand" />
                </NavLink>
            }
        </div>
    );
}


function MenuItem(props: { className?: string, linkTo: string, text: string, last?: boolean, children?: React.ReactNode }): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const searchContext = useSearchContext();

    const navLinkClass = ({ isActive, isPending }: {
        isActive: boolean;
        isPending: boolean;
    }) => {
        return isActive ? `relative underline underline-offset-4` : '';
    };

    const extraStyle = props.last ? 'right-0' : '';


    if (props.children)
        return (
            <div
                className="relative self-stretch inline-block px-3 py-3 transition border-t border-solid lg:px-0 lg:py-3 group border-t-tk-grey lg:border-0"
                onClick={(e) => {
                    setOpen(!open);
                }}
            >
                <div className={"flex whitespace-nowrap hover:underline hover:underline-offset-4 group-hover:no-underline"}>
                    <div className="flex justify-between w-full">
                        <NavLinkWithContextMenu to={props.linkTo} className={navLinkClass}>
                            <span>{props.text}</span>
                        </NavLinkWithContextMenu>
                        <img className="inline-block w-3 ml-1 transition lg:group-hover:rotate-180" src={img_arrow} alt="" />
                    </div>
                </div>
                <div className={`px-3 py-4 mt-3 text-sm bg-white border border-gray-200 border-solid lg:px-5 lg:mt-0 ${open ? 'block' : 'hidden'} lg:hidden lg:z-50 lg:absolute lg:group-hover:block border-t-tk-brand ${extraStyle}`}>
                    {props.children}
                </div>
            </div >)

    return (
        <div className={`block px-8 py-3 md:inline md:w-auto md:px-3 md:py-2 ${props.className}`}>
            <NavLink to={props.linkTo} className={navLinkClass} onClick={() => searchContext.reset()}>{props.text}</NavLink>
        </div>
    )
}

