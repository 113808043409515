import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import img_profile from '../images/BewerbungsgespraecheFuehren/profile.png';
import img_Hero from '../images/Fuehrungsinstrumente/hero_bg.png';

export default function FührungsinstrumentePage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <SmallTiles />
                        <HRBP />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        {/* <FührungsSideLinks /> */}
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Unsere Führungsinstrumente sollen dazu
                dienen, Ziele zu definieren, Entscheidungen zu
                treffen und Leistung zu messen.
            </TKText.Header>
            <TKText.SubHeader>
                Mit den nachfolgenden Instrumenten kann man Leistung strukturiert und
                differenziert einschätzen, zu der Leistung Feedback geben und gemeinsam
                Entwicklungsmöglichkeiten ableiten.
            </TKText.SubHeader>
            <TKText.Body>
                Die Instrumente werden als Standard von HR zur Verfügung gestellt, sind aber Führungsinstrumente, die Sie als
                Führungskraft nutzen und damit Ihr Team weiterentwickeln können.
            </TKText.Body>
            <TKText.SubHeader>
                Auch hier gilt, gute Vorbereitung ist alles.
            </TKText.SubHeader>
            <TKText.Body>
                Belegen Sie an konkreten Situationen, die Sie im Arbeitsalltag beobachtet haben. Denken Sie auch daran nach der
                Selbsteinschätzung des Mitarbeitenden zu fragen. Stimmt ihr Blick mit dem Ihres Mitarbeitenden überein?
                Eine gute Grundlage für eine gemeinsame Diskussion.
            </TKText.Body>
        </Section >
    )
}

function HRBP() {
    return (
        <Section className="grid grid-cols-1 gap-3 px-8 py-6 border lg:grid-cols-3 bg-tk-brand boder-solid boder-tk-text-grey-light">
            <Link to={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/hr-services-und-ansprechpartnerinnen-fuer-ihre-personalthemen/hr-services-und-ansprechpartner-fuer-fuehrungsthemen"} target="_blank" className="col-span-1">
                <img src={img_profile} alt="" className="w-44" />
            </Link>
            <div className="col-span-2 py-6 text-xl text-white lg:py-16 lg:px-6 ">
                Für weitergehende Informationen wenden <br />
                Sie sich gerne an die zuständigen HR-<br />
                Services für Führungsthemen.
            </div>
        </Section>
    )
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/grundlagen/lupsteel" >
                    <SmallTile.Title>
                        LuP@Steel
                    </SmallTile.Title>
                    <SmallTile.Body>
                        <br />
                        Mehr zu unserem
                        integrierten Talent- & Performance-Prozess zur
                        Leistungsbeurteilung und Potenzialentwicklung.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/verguetung-und-zusatzleistungen/tarifentgelte-se-ag/die-neue-leistungsbeurteilung-fuer-tarifangestellte-bei-der-thyssenkrupp-steel-europe-ag" >
                    <SmallTile.Title>
                        Leistungszulage<br />
                        Tarif
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Mehr Informationen zu<br />
                        Leistungsprämien.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/grundlagen" >
                    <SmallTile.Title>
                        Grundlagen
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Hier findest Du unsere Werte, Kompetenzmodelle  und Potenzialindikatoren.
                    </SmallTile.Body>
                </SmallTile>
            </div>
        </Section>
    )
}


