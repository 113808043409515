import { BrowserRouter, Routes as DomRoutes, Route } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";
import AnsprechpartnerPage from "../pages/AnsprechpartnerPage";
import BewerbungsgesprächeFühren from "../pages/BewerbungsgesprächeFühren";
import EntwickelnPage from "../pages/EntwickelnPage";
import FLKZielbildPage from "../pages/FLKZielbildPage";
import FeedbackNehmenUndGeben from "../pages/FeedbackNehmenUndGeben";
import FührungsUndLeistungskulturPage from "../pages/FührungsUndLeistungskulturPage";
import FührungsinstrumentePage from "../pages/FührungsinstrumentePage";
import FührungsverständnisStärkenPage from "../pages/FührungsverständnisStärkenPage";
import GesprächeFührenPage from "../pages/GesprächeFührenPage";
import GutKommunizieren from "../pages/GutKommunizieren";
import HomePage from "../pages/HomePage";
import FraueninFuehrungspositionenPage from "../pages/LGBTQ+/FraueninFuehrungspositionenPage";
import PrideAtSteelPage from "../pages/LGBTQ+/PrideAtSteelPage";
import VielfaltAlsChancePage from "../pages/LGBTQ+/VielfaltAlsChancePage";
import MediathekPage from "../pages/MediathekPage";
import MeineAufgabenKennen from "../pages/MeineAufgabenKennen";
import MeineRolleAlsFührungskraftPage from "../pages/MeineRolleAlsFührungskraftPage";
import MeinenVerantwortungsbereichPage from "../pages/MeinenVerantwortungsbereichPage";
import MichEinarbeitenPage from "../pages/MichEinarbeitenPage";
import MichEntwickelnPage from "../pages/MichEntwickelnPage";
import MitarbaiterGewinnenPage from "../pages/MitarbaiterGewinnenPage";
import MitarbeitendeEntwickelnPage from "../pages/MitarbeitendeEntwickelnPage";
import MitarbeiterOnboardenPage from "../pages/MitarbeiterOnboardenPage";
import TeamEntwickelnPage from "../pages/TeamEntwickelnPage";
import WerkzeugeFindenPage from "../pages/WerkzeugeFindenPage";
import WildcardPage from "../pages/WildcardPage";
import ZielbildarbeitInBereichenPage from "../pages/ZielbildarbeitInBereichenPage";
import links from "../pages/links";
import Privacy from "./Privacy";
import TabConfig from "./TabConfig";
import TermsOfUse from "./TermsOfUse";

export default function Routes(props: {}): JSX.Element {

    return (
        <BrowserRouter>
            <ScrollToTop />
            <DomRoutes>
                <Route path={links.home} element={<HomePage />} />
                <Route path={links.Mediathek} element={<MediathekPage />} />
                <Route path={links["Gespräche führen"]} element={<GesprächeFührenPage />} />
                <Route path={links["Werkzeuge finden"]} element={<WerkzeugeFindenPage />} />
                <Route path={links["Feedback nehmen und geben"]} element={<FeedbackNehmenUndGeben />} />
                <Route path={links["meine Aufgaben kennen"]} element={<MeineAufgabenKennen />} />
                <Route path={links["entwickeln"]} element={<EntwickelnPage />} />
                <Route path={links["mich einarbeiten"]} element={<MichEinarbeitenPage />} />
                <Route path={links["Gut kommunizieren"]} element={<GutKommunizieren />} />
                <Route path={links["Bewerbungsgespräche führen"]} element={<BewerbungsgesprächeFühren />} />
                <Route path={links["Führungsinstrumente"]} element={<FührungsinstrumentePage />} />
                <Route path={links["mich entwickeln"]} element={<MichEntwickelnPage />} />
                <Route path={links["Mitarbeitende entwickeln"]} element={<MitarbeitendeEntwickelnPage />} />
                <Route path={links["Meinen Verantwortungsbereich"]} element={<MeinenVerantwortungsbereichPage />} />
                <Route path={links["FLK Zielbild"]} element={<FLKZielbildPage />} />
                <Route path={links["Führungsverständnis stärken"]} element={<FührungsverständnisStärkenPage />} />
                <Route path={links["Meine Rolle als Führungskraft"]} element={<MeineRolleAlsFührungskraftPage />} />
                <Route path={links["Team entwickeln"]} element={<TeamEntwickelnPage />} />
                <Route path={links["Führungs und Leistungskultur"]} element={<FührungsUndLeistungskulturPage />} />
                <Route path={links["Ansprechpartner"]} element={<AnsprechpartnerPage />} />
                <Route path={links["Mitarbeiter gewinnen"]} element={<MitarbaiterGewinnenPage />} />
                <Route path={links["Mitarbeiter onboarden"]} element={<MitarbeiterOnboardenPage />} />
                {/* <Route path={links["FLK-Dialog"]} element={<FLKDialogPage />} /> */}
                <Route path={links["Zielbildarbeit in Bereichen und der strategische Treiber FLK"]} element={<ZielbildarbeitInBereichenPage />} />
                <Route path={links["LGBTQ+_Vielfalt als Chance"]} element={<VielfaltAlsChancePage />} />
                <Route path={links["LGBTQ+_Frauen in Führungspositionen"]} element={<FraueninFuehrungspositionenPage />} />
                <Route path={links["LGBTQ+_pride@steel"]} element={<PrideAtSteelPage />} />
                {/* <Route path="/" element={<TestPage />} /> */}
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/config" element={<TabConfig />} />
                <Route path="*" element={<WildcardPage />} />
            </DomRoutes>
        </BrowserRouter>
    );
}