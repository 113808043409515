const links = {
  home: "/home",
  Mediathek: "/Mediathek",
  "Gespräche führen": "/Gespräche führen",
  "Werkzeuge finden": "/Werkzeuge finden",
  "Feedback nehmen und geben": "/Feedback nehmen und geben",
  "meine Aufgaben kennen": "/meine Aufgaben kennen",
  entwickeln: "/entwickeln",
  "mich einarbeiten": "/mich einarbeiten",
  "Gut kommunizieren": "/Gut kommunizieren",
  "Bewerbungsgespräche führen": "/Bewerbungsgespräche führen",
  Führungsinstrumente: "/Führungsinstrumente",
  "mich entwickeln": "/mich entwickeln",
  "Mitarbeitende entwickeln": "/Mitarbeitende entwickeln",
  "Meinen Verantwortungsbereich":
    "/Meinen Verantwortungsbereich strategisch weiterentwickeln",
  "FLK Zielbild": "/FLK Zielbild",
  "Führungsverständnis stärken": "/Führungsverständnis stärken",
  "Meine Rolle als Führungskraft": "/Meine Rolle als Führungskraft",
  "Team entwickeln": "/Team entwickeln",
  "Führungs und Leistungskultur": "/Führungs und Leistungskultur",
  Ansprechpartner: "/Ansprechpartner",
  "Mitarbeiter gewinnen": "/Mitarbeiter gewinnen",
  "Mitarbeiter onboarden": "/Mitarbeiter onboarden",
  "FLK-Dialog": "/FLK-Dialog",
  "Zielbildarbeit in Bereichen und der strategische Treiber FLK":
    "/Zielbildarbeit in Bereichen und der strategische Treiber FLK",
  "LGBTQ+_Vielfalt als Chance": "/Vielfalt als Chance",
  "LGBTQ+_Frauen in Führungspositionen": "/Frauen in Führungspositionen",
  "LGBTQ+_pride@steel": "/pride@steel",
};

export default links;
