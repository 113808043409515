import { useState } from 'react';
import BasicLayout from '../../components/BasicLayout';
import ContainerCenter from "../../components/ContainerCenter";
import HeroImage from '../../components/HeroImage';
import { RelevanteDokumente } from '../../components/RelevanteDokumente';
import Section from "../../components/Section";
import TKText from "../../components/TKText";
import img_Hero from '../../images/LGBTQPlus/Header_Vielfalt_als_Chance.jpg';
import img_Infographic from '../../images/LGBTQPlus/Infographic_LGTBQplus.png';
import img_Diversity from '../../images/LGBTQPlus/Logo_thyssen_Diversity.png';
import img_video_preview from '../../images/LGBTQPlus/video_preview.png';

import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import { SideLinkItem } from '../../components/SideLinkItem';
import SmallTile from '../../components/SmallTile';
import VideoComponent from '../../components/VideoComponent';
import { RelevantDocument } from '../../types/RelevantDocument';
import links from '../links';

export const Documents: RelevantDocument[] = [
    {
        title: "Informationen und Handlungshilfen für Führungskräfte und Mitarbeitende",
        size: "604 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=70b7e7bc-3880-4bf9-8723-9b840a9f8b7e"
    },
    {
        title: "Mikroagressionen",
        size: "3,7 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=3177775a-1157-4e46-856c-487d231ee04b"
    },
    {
        title: "Eine kurze Einführung in Gender Transition",
        size: "314 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=6d141b77-244e-4494-847a-3dcb111525b5"
    },
];


export default function VielfaltAlsChancePage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["LGBTQ+_Vielfalt als Chance"]}>Vielfalt als Chance</Link>,
                ]} />
                <WarumDiverseSection />
                <FührungskraefteTrainingSection />
                <SimpleShowVideoSection />
                <DieDiversityDimensionenSection />
                <SmallTiles />
                <DiversitySeiteImSteelNet />
                <DocumentsSection />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Vielfalt als Chance">
                {/* <HeroImage.Title>Vielfalt als</HeroImage.Title>
                <HeroImage.Title>Chance</HeroImage.Title> */}
            </HeroImage>
        </Section>
    )
}

function WarumDiverseSection() {

    return (
        <Section>
            <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                <div className="col-span-12 pr-20 lg:col-span-9">
                    <TKText.Header>
                        Warum diverse Unternehmen erfolgreicher sind
                    </TKText.Header>
                    <TKText.SubHeader>
                        Vielfalt und Inklusion sind keine Option, sondern eine unternehmerische Notwendigkeit. Durch die Integration unterschiedlicher Perspektiven, Hintergründe und Fähigkeiten fördern wir Innovationen und stärken die Wettbewerbsfähigkeit und Arbeitgeberattraktivität.
                    </TKText.SubHeader>
                    <TKText.SubHeader>
                        Als Führungskraft liegt es in Ihrer Verantwortung, eine inklusive Arbeitsumgebung zu schaffen, in der Mitarbeitende ihre Potenziale entfalten können und sich wertgeschätzt und respektiert fühlen.
                    </TKText.SubHeader>
                    <h4 className='mt-4 mb-4 text-base text-tk-brand font-tk-medium'>
                        „Diversity is not about how we differ. Diversity is about embracing one another’s uniqueness.”
                    </h4>
                    <p className='-mt-4 text-xs'>Ola Joseph</p>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <SideLinks />
                </div>
            </div>
        </Section >
    )
}

function FührungskraefteTrainingSection() {
    return (
        <Section className='grid grid-cols-8 gap-4 mt-20 bg-[#edf0f2]'>
            <div className='col-span-5 p-6'>
                <h4 className='mb-2 text-base text-tk-brand font-tk-bold'>
                    Führungskräfte Training
                </h4>
                <p className='mb-2 text-2xl text-tk-text-grey-light font-tk-bold'>
                    „Unconscious Bias – Bewusster Umgang mit unbewussten Denkmustern“
                </p>
                <TKText.SubHeader>
                    Unbewusste Vorurteile hat jeder. In dem Training lernen Sie die Mechanismen unbewusster Denkmuster kennen und wie Sie Schubladendenken überwinden können.
                </TKText.SubHeader>
                <a
                    href='https://wd3.myworkday.com/steeleurope/learning/course/f5abfdbbc928100146ffb350a95b0000?record=f5abfdbbc92810014b19411ad7980001&type=9882927d138b100019b928e75843018d'
                    target='_blank'
                    className='inline-block p-4 px-4 pb-2 mt-2 leading-none border font-tk-medium border-tk-brand pt-2.5'
                    rel="noreferrer">
                    Jetzt Training starten →
                </a>
            </div>
            <div className='flex items-center justify-center col-span-3 px-14'>
                <img src={img_Diversity} alt='' className='mx-auto' />
            </div>
        </Section>

    )
}

function SimpleShowVideoSection() {
    return (
        <Section className='grid grid-cols-8 gap-4 mt-20'>
            <div className='col-span-5 pr-6'>
                <h4 className='mb-4 text-lg text-tk-brand font-tk-medium'>
                    Simple Show Video: „Sprachgebrauch“
                </h4>
                <VideoComponent
                    url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=d948aee5-beb2-4a6e-9d9b-7c6be491256b&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                    preview={img_video_preview}
                    height={280}
                />
            </div>
            <div className='flex flex-col justify-start col-span-3 pr-4'>
                <h4 className='mt-10 mb-4 text-3xl text-black font-tk-regular'>
                    Wolltest du das wirklich so sagen?
                </h4>
                <TKText.SubHeader>
                    Was sind überhaupt Diversity Dimensionen und warum ist es für Unternehmen wichtig, sich damit zu beschäftigen?
                </TKText.SubHeader>
                <p className='mt-4 text-xl font-tk-medium'>
                    Das Video erklärt es.
                </p>
            </div>
        </Section>
    )
}

function DieDiversityDimensionenSection() {
    const [imgZoom, setImgZoom] = useState(false);

    return (
        <Section className='grid grid-cols-8 gap-4 mt-20'>
            <div className='col-span-4 pr-6'>
                <h4 className='mb-8 text-lg text-tk-brand font-tk-medium'>
                    Die Diversity Dimensionen im Modell der Persönlichkeit
                </h4>
                <img src={img_Infographic} alt='' className={`mx-auto transition-all duration-500 ease-in cursor-pointer lg:ml-0 ${imgZoom ? 'w-full pl-0' : 'lg:pl-10 w-4/5'}`} onClick={() => setImgZoom(!imgZoom)} />
                <div className={`mx-auto mt-10 text-xs text-center transition-all duration-500 ease-in text-tk-text-grey lg:ml-0 ${imgZoom ? 'w-full' : 'lg:w-5/6'}`} onClick={() => setImgZoom(!imgZoom)}>Zum Vergrößern klicken</div>
            </div>
            <div className='flex flex-col justify-center col-span-4 p-4'>
                <TKText.SubHeader >
                    Diversity Dimensionen beziehen sich auf die verschiedenen Aspekte, in denen Menschen sich unterscheiden und die die Vielfalt in einer Gesellschaft oder einem Unternehmen ausmachen.
                </TKText.SubHeader>
                <TKText.SubHeader>
                    Diese Dimensionen spielen eine wichtige Rolle bei der Förderung von Inklusion und Chancengleichheit. Sie zu erkennen und zu respektieren, trägt dazu bei, eine offene und wertschätzende Gesellschafts- und Unternehmenskultur zu schaffen.
                </TKText.SubHeader>
            </div>
        </Section>
    )
}

export function SideLinks() {
    return (
        <>
            <h4 className='mb-3 text-lg'>Themenseiten</h4>
            <SideLinkItem url={links["Führungsverständnis stärken"]}>
                Führungsverständnis stärken
            </SideLinkItem>
            <SideLinkItem url={links["LGBTQ+_Vielfalt als Chance"]}>
                Vielfalt als Chance
            </SideLinkItem>
            <SideLinkItem url={links["LGBTQ+_Frauen in Führungspositionen"]}>
                Frauen in Führungspositionen
            </SideLinkItem>
            <SideLinkItem url={links["LGBTQ+_pride@steel"]}>
                pride@steel
            </SideLinkItem>
        </>
    )
}
function SmallTiles() {
    return (
        <Section className='mt-20'>
            <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                <div className="col-span-12 lg:col-span-8">
                    <div className="grid grid-cols-6 gap-5">
                        <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo={links["LGBTQ+_Frauen in Führungspositionen"]}>
                            <SmallTile.Title>
                                Frauen in Führungspositionen
                            </SmallTile.Title>
                            <SmallTile.Body>
                                Die Förderung von Frauen in Führungsrollen ist entscheidend für Vielfalt, Innovation und wirtschaft- lichen Erfolg.
                            </SmallTile.Body>
                        </SmallTile>
                        <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo={links["LGBTQ+_pride@steel"]}>
                            <SmallTile.Title>
                                pride@steel
                            </SmallTile.Title>
                            <SmallTile.Body>
                                Das Netzwerk unterstützt LGBTQ+ Mitarbeiter*innen und schafft ein Arbeitsumfeld, in dem alle sich respektiert und wertgeschätzt fühlen.
                            </SmallTile.Body>
                        </SmallTile>
                        <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo={'https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management'}>
                            <SmallTile.Title>
                                Diversity-Seite im steel.net
                            </SmallTile.Title>
                            <SmallTile.Body>
                                Hier finden Sie hilfreiche Informationen, Ressourcen und Anlaufstellen für mehr Diversität und Inklusion im Arbeitsumfeld.
                            </SmallTile.Body>
                        </SmallTile>
                    </div>
                </div>
            </div>
        </Section>
    )
}

function DiversitySeiteImSteelNet() {
    return (
        <Section className='mt-20'>
            <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                <div className="col-span-12 lg:col-span-8">
                    <h4 className='mb-8 text-3xl text-black font-tk-regular'>
                        Diversity-Seite im steel.net
                    </h4>
                    <TKText.SubHeader>
                        Auf unserer Intranet-Seite zum Thema Diversity & Inclusion finden Sie hilfreiche Informationen, Ressourcen und Anlaufstellen für mehr Diversität und Inklusion im Arbeitsumfeld. Entdecken Sie, wie wir gemeinsam ein unterstützendes und offenes Miteinander fördern können.
                    </TKText.SubHeader>
                    <a href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management"
                        target="_blank"
                        rel="noreferrer"
                        className='inline-block p-4 px-4 pb-2 mt-2 leading-none border text-tk-brand font-tk-medium border-tk-brand pt-2.5'>
                        Zur Diversity-Seite im steel.net
                    </a>
                </div>
            </div>
        </Section>
    )
}
export function DocumentsSection() {
    return (
        <div className="grid grid-cols-12 gap-3 p-4 mt-20 lg:p-0">
            <div className="col-span-12 lg:col-span-8">
                <RelevanteDokumente docs={[Documents[0], Documents[1]]} subtitle='lgbt+ bei Steel' />
                {/* <RelevanteDokumente docs={[Documents[2]]} title="" subtitle='Gender Transition' /> */}
            </div>
            <div className="hidden lg:col-span-1 lg:block">
            </div>
            <div className="col-span-12 lg:col-span-3">

            </div>
        </div>
    )
}
