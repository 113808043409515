import Section from "./Section";

const HeroImage = (props: { image: string, alt: string, children?: React.ReactNode }): JSX.Element => {
    return (
        <Section>
            <img src={props.image} alt={props.alt} className="w-full" />
            <div className="relative">
                <div className="absolute left-0 bottom-8">
                    {props.children}
                </div>
            </div>
        </Section>
    );
}

HeroImage.Title = (props: { children: React.ReactNode }): JSX.Element => {
    return (
        <div>
            <div className="inline-block pt-2 pb-1 pl-4 pr-2 mb-1 text-4xl leading-none text-white bg-tk-brand">{props.children}</div>
        </div>
    )
}


export default HeroImage;