
import { useState } from "react";
import { Link } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import Breadcrumb from "../../components/Breadcrumb";
import ContainerCenter from "../../components/ContainerCenter";
import HeroImage from "../../components/HeroImage";
import { RelevanteDokumente } from "../../components/RelevanteDokumente";
import Section from "../../components/Section";
import TKText from "../../components/TKText";
import img_Hero from '../../images/LGBTQPlus/Header_Pride_at_Steel.jpg';
import img_icon_mail from '../../images/LGBTQPlus/Icon_mail.png';
import img_Illustration_lgbtq from '../../images/LGBTQPlus/Illustration_lgbtq.jpg';
import img_Preview_Handbook from '../../images/LGBTQPlus/Preview_Handbook.jpg';
import img_pride from '../../images/LGBTQPlus/Pride_at_steel.png';
import img_Infographic from '../../images/LGBTQPlus/Wheel_pride.png';
import { RelevantDocument } from "../../types/RelevantDocument";
import links from "../links";
import { SideLinks } from "./VielfaltAlsChancePage";



export const Documents: RelevantDocument[] = [
    {
        title: "Mikroagressionen Präsentation",
        size: "3,7 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=3177775a-1157-4e46-856c-487d231ee04b"
    },
    {
        title: "Handlungshilfe zu LGBT+",
        size: "??? KB",
        url: "#"
    },
    {
        title: "Gendergerechte Sprache",
        size: "3,2 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=afa66c94-b96b-422d-b726-6fa8e78cdfc2"
    },
    {
        title: "Eine kurze Einführung in Gender Transition",
        size: "314 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=6d141b77-244e-4494-847a-3dcb111525b5"
    },
    {
        title: "Informationen und Handlungshilfen für Führungskräfte und Mitarbeitende",
        size: "604 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=70b7e7bc-3880-4bf9-8723-9b840a9f8b7e"
    },
];

export default function PrideAtSteelPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["LGBTQ+_pride@steel"]}>pride@steel</Link>,
                ]} />
                <PrideSteelSection />
                <MehrAlsSieDenkenSection />
                <WieKoennenSieEinInklusivesArbeitsumfeldSchaffenSection />
                <DocumentsSection />
            </ContainerCenter>
        </BasicLayout>
    );
}


function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Führungs- & Leistungskultur" />
        </Section>
    )
}

function PrideSteelSection() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-3 p-4 mb-20 lg:p-0">
                <div className="col-span-12 pr-8 lg:col-span-9">
                    <TKText.Header>
                        Das Netzwerk der LGBT+ Community
                    </TKText.Header>
                    <TKText.SubHeader>
                        Wir schätzen die Vielfalt unserer Belegschaft und betrachten sie als entscheidend für den Unternehmenserfolg. Entsprechend unserer Unternehmenskultur fördern wir bei der thyssenkrupp Steel ein offenes und respektvolles Arbeitsumfeld, in dem sich alle Beschäftigten unabhängig ihrer sexuellen Orientierung und/oder Identität, willkommen fühlen.
                    </TKText.SubHeader>
                    <img src={img_pride} alt='' className="mt-4 mb-10 w-80" />
                    <a href="https://steel-net.thyssenkrupp-steel.com/de/pride"
                        target='_blank'
                        className='inline-block px-4 mb-8 mr-10 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'
                        rel="noreferrer" >
                        Zum Netzwerk pride@steel
                    </a>
                    <a href="mailto:pride-steel@thyssenkrupp.com" className='inline-block px-4 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'>
                        <img src={img_icon_mail} className='inline-block w-6 mr-2' alt='' />
                        pride-steel@thyssenkrupp.com
                    </a>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <SideLinks />
                    <div className="flex justify-center mt-10">
                        <img src={img_Infographic} alt='' className="w-full mx-auto" />
                    </div>
                </div>
            </div>

        </Section >
    )
}

function MehrAlsSieDenkenSection() {
    const [imageZoom, setImageZoom] = useState(false);

    return (
        <Section className="mt-20">
            <div className="grid grid-cols-12 gap-20">
                <div className={`${imageZoom ? 'col-span-12' : 'col-span-6'}`}>
                    <div className="">
                        <img src={img_Illustration_lgbtq} alt='' className={`w-full cursor-pointer`} onClick={() => setImageZoom(!imageZoom)} />
                        <span className={`block mx-auto mt-4 text-xs text-center text-tk-text-grey`} onClick={() => setImageZoom(!imageZoom)}>Zum Vergrößern klicken</span>
                    </div>
                </div>
                <div className={`${imageZoom ? 'col-span-12' : 'col-span-6'}`}>
                    <TKText.Header>
                        Mehr als Sie denken
                    </TKText.Header>
                    <TKText.SubHeader>
                        Sprechen wir von der lgbt+ Community, dann sind es mehr, als Sie vielleicht denken.
                    </TKText.SubHeader>
                    <TKText.Body>
                        Geht man davon aus, dass sich 7,4 % der Menschen in Europa mit lgbt+ identifizieren und brechen dies auf Mitarbeitende bei Steel herunter, dann können wir davon ausgehen, dass knapp 2.000 Beschäftigte sich der Community zugehörig fühlen.
                    </TKText.Body>

                </div>
            </div>

        </Section>
    )
}

function WieKoennenSieEinInklusivesArbeitsumfeldSchaffenSection() {
    const [imageZoom, setImageZoom] = useState(false);
    return (
        <Section className="mt-20">
            <TKText.Header className="mb-4">
                Wie können Sie ein inklusives Arbeitsumfeld schaffen?
            </TKText.Header>
            <div className="grid grid-cols-12 gap-20">
                <div className={`${imageZoom ? 'col-span-12' : 'col-span-6'}`}>
                    <TKText.SubHeader>
                        Mit dieser Handlungshilfe erfahren Sie, warum Diversität & Inklusion für Steel wichtig sind, was es mit lgbt+ auf sich hat und wie Sie als Führungskraft ganz durch Ihr (Führungs-)Verhalten zu einer wertschätzenden und vorurteilsfreien Unternehmenskultur beitragen können.
                    </TKText.SubHeader>
                </div>
                <div className={`${imageZoom ? 'col-span-12' : 'col-span-6'}`}>
                    <div className="">
                        <img src={img_Preview_Handbook} alt='' className="w-full h-full cursor-pointer" onClick={() => setImageZoom(!imageZoom)} />
                        <span className={`block mx-auto mt-4 text-xs text-center text-tk-text-grey`} onClick={() => setImageZoom(!imageZoom)}>Zum Vergrößern klicken</span>
                    </div>
                </div>
            </div>

        </Section>
    )
}

function DocumentsSection() {
    return (
        <div className="grid grid-cols-12 gap-3 p-4 mt-20 lg:p-0">
            <div className="col-span-12 lg:col-span-8">
                <RelevanteDokumente docs={[Documents[0], Documents[4]]} subtitle='lgbt+ bei Steel' />
                {/* <RelevanteDokumente docs={[Documents[1]]} title="" subtitle='Handlungshilfen' /> */}
                <RelevanteDokumente docs={[Documents[2]]} title="" subtitle='Gendergerechte Sprache' />
                <RelevanteDokumente docs={[Documents[3]]} title="" subtitle='Gender Transition' />
            </div>
            <div className="hidden lg:col-span-1 lg:block">
            </div>
            <div className="col-span-12 lg:col-span-3">

            </div>
        </div>
    )
}